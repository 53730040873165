export type ServiceCardType = {
    title: {
        [key: string]: string
    },
    text: {
        [key: string]: string
    },

}

export type ServiceType = {
    title: {
        [key: string]: string
    },
    text: {
        [key: string]: string
    },
    img: any,
}


export const services_cards: ServiceCardType[] = [
    {
        title: {
            ar: "إدارة الرعاية الصحية",
            en: "Health care management"
        },
        text: {
            ar: "نسهل ونوفر البيانات الطبية والمالية برقم مرجع دائم بتحديد أحقية المشتركين ونستخدم التقنيات وفق الشروط التعاقدية ونمنع ونراقب الأسعار والتجاوزات ونضبط ونضمن صرف الأدوية وكمياتها للحفاظ على التوازن و الجودة و التكلفة.",
            en: "We facilitate and provide medical and financial data with a permanent reference number by determining the eligibility of subscribers according to the contractual terms, preventing and monitoring prices and violations, and controlling and ensuring the dispensing of medicines and their quantities to maintain balance, quality and cost."
        },

    },
    {
        title: {
            ar: "الإستشارات التأمينية والصحية",
            en: "Insurance and health consultations"
        },
        text: {
            ar: "لأننا رواداَ في إدارة النفقات الطبية نحن نوفر الاستشارات وتدريبا شاملا ونقدم الدعم في الدراسات والبحوث والمشاركة في المناقصات من خلال خبراؤنا المحليين والدوليين، نهدف أن نكون المرجعية لشركات التأمين للجهات العامة والخاصة.",
            en: "Because we are pioneers in managing medical expenses, we provide consultations and comprehensive training, and provide support in studies and research and participation in tenders through our local and international experts. We aim to be the reference for insurance companies for public and private entities."
        },
    },
    {
        title: {
            ar: "خدمات الصحية الدولية",
            en: "International health services"
        },
        text: {
            ar: "نوفر وثائق التأمين الصحي الدولي ونضمن للمرضى الرأي الطبي الثاني وخطط علاج متكاملة مع تسهيل الحصول على تأشيرات الدخول وتنسيق سفرهم وتنقلهم والترجمة من خلال شركائنا وعلاقتنا مع مؤسسات الرعاية الصحية الدولية.",
            en: "We provide international health insurance documents and guarantee patients a second medical opinion and integrated treatment plans, while facilitating obtaining entry visas and coordinating their travel, transportation, and translation through our partners and our relationship with international health care institutions."
        },
    },
    {
        title: {
            ar: "خدمات الإسعاف الطائر",
            en: "Flying ambulance services"
        },
        text: {
            ar: "خدماتنا تشمل التنسيق الطبي مع تسهيل إصدار التصاريح وتبادل التقارير الطبية مع المستشفى المراد الانتقال إليه ويشمل ذلك المرافقين وتسهيل إصدار التأشيرات للبلدان كالاتحاد الأوروبي، نستخدم الطائرات والهليكوبتر المجهزة بأحدث الأجهزة الطبية.",
            en: "Our services include medical coordination, facilitating the issuance of permits, exchanging medical reports with the hospital to which you want to move, including companions, and facilitating the issuance of visas to countries such as the European Union. We use airplanes and helicopters equipped with the latest medical equipment."
        },

    },
    {
        title: {
            ar: "الخدمات الإلكترونية",
            en: "electronic services"
        },
        text: {
            ar: "في الرعاية العالمية نقدم خدمات إلكترونية تتضمن التقديرات الإكتوارية والإحصائيات لشركائنا وندعم خطط إدارة الإخطار لشركات التأمين ونقوم برصد مؤشرات الأداء وتحليل التعاقدات بما في ذلك توزيع المطالبات لتحسين الأداء وتقليل المخاطر.",
            en: "At Global Care, we provide electronic services that include actuarial estimates and statistics to our partners, support notification management plans for insurance companies, and monitor performance indicators and analyze contracts, including claims distribution, to improve performance and reduce risks."
        },

    },
    {
        title: {
            ar: "خدمات الدعم والمساندة",
            en: "Support services"
        },
        text: {
            ar: "نحن على جاهزية دائمة لتقديم الدعم ومساعدة المشتركين والإجابة على استفساراتهم بهدف تطوير الخدمات الطبية بما يتوافق مع المستوى المطلوب ونحن على استعداد لتنظيم اجتماعات دورية لمناقشة الجوانب التقنية والمالية المتعلقة بهذا التعاقدات.",
            en: "We are always ready to provide support and assistance to subscribers and answer their inquiries with the aim of developing medical services in accordance with the required level. We are ready to organize periodic meetings to discuss the technical and financial aspects related to these contracts."
        }
    },

]


const services: ServiceType[] = [

    {
        title: {
            ar: "إدارة الرعاية الصحية",
            en: "Health Care Management"
        },
        text: {
            ar: `
            نسهل ونوفر البيانات الطبية والمالية برقم مرجع دائم بتحديد أحقية المشتركين وفق الشروط التعاقدية ونمنع ونراقب الأسعار والتجاوزات ونضبط ونضمن صرف الأدوية وكمياتها للحفاظ على التوازن والتكلفة.
            `,
            en: `
            We facilitate and provide medical and financial data with a permanent reference number by determining the eligibility of subscribers according to the contractual terms, preventing and monitoring prices and violations, and controlling and ensuring the dispensing of medicines and their quantities to maintain balance and cost.
            `
        },
        img: require('src/assets/images/services/1.png'),
    },
    {
        title: {
            ar: "الاستشارات التأمينية والصحية",
            en: "Insurance and health consultations"
        },
        text: {
            ar: `
            لأننا رواداَ في إدارة النفقات الطبية نحن نوفر الاستشارات وتدريبا شاملا ونقدم الدعم في الدراسات والبحوث والمشاركة في المناقصات من خلال خبراؤنا المحليين والدوليين، نهدف أن نكون المرجعية لشركات التأمين للجهات العامة والخاصة.
            `,
            en: `
            Because we are pioneers in managing medical expenses, we provide consultations and comprehensive training, and provide support in studies and research and participation in tenders through our local and international experts. We aim to be the reference for insurance companies for public and private entities.
            `
        },
        img: require('src/assets/images/services/2.png'),
    },
    {
        title: {
            ar: "نوفر خدمات صحية دولية",
            en: "We provide international health services"
        },
        text: {
            ar: `
            نوفر وثائق التأمين الصحي الدولي ونضمن للمرضى الرأي الطبي الثاني وخطط علاج متكاملة مع تسهيل الحصول على تأشيرات الدخول وتنسيق سفرهم وتنقلهم والترجمة من خلال شركائنا وعلاقتنا مع مؤسسات الرعاية الصحية الدولية.
            `,
            en: `
            We provide international health insurance documents and guarantee patients a second medical opinion and integrated treatment plans, while facilitating obtaining entry visas and coordinating their travel, transportation, and translation through our partners and our relationship with international health care institutions.
            `
        },
        img: require('src/assets/images/services/3.png'),
    },
    {
        title: {
            ar: " الإسعاف الطائر",
            en: "Flying ambulance"
        },
        text: {
            ar: `
            خدماتنا تشمل التنسيق الطبي مع تسهيل إصدار التصاريح وتبادل التقارير الطبية مع المستشفى المراد الانتقال إليه ويشمل ذلك المرافقين وتسهيل إصدار التأشيرات للبلدان كالاتحاد الأوروبي، نستخدم الطائرات والهليكوبتر المجهزة بأحدث الأجهزة الطبية. 
            `,
            en: `
            Our services include medical coordination, facilitating the issuance of permits, exchanging medical reports with the hospital to which you want to move, including companions, and facilitating the issuance of visas to countries such as the European Union. We use airplanes and helicopters equipped with the latest medical equipment.
            `
        },
        img: require('src/assets/images/services/4.png'),

    },
    {
        title: {
            ar: "الخدمات الإلكترونية",
            en: "electronic services"
        },
        text: {
            ar: `
            نقدم خدمات إلكترونية تتضمن التقديرات الإكتوارية والإحصائيات لشركائنا وندعم خطط إدارة الإخطار لشركات التأمين ونقوم برصد مؤشرات الأداء وتحليل التعاقدات بما في ذلك توزيع المطالبات لتحسين الأداء وتقليل المخاطر.
            `,
            en: `
            We provide electronic services that include actuarial estimates and statistics to our partners, support notification management plans for insurance companies, and monitor performance indicators and analyze contracts, including claims distribution, to improve performance and reduce risks.
            `
        },
        img: require('src/assets/images/services/5.png'),
    },
    {
        title: {
            ar: "خدمات الدعم والمساندة",
            en: "Support and assistance services"
        },
        text: {
            ar: `
            نحن على جاهزية دائمة لتقديم الدعم ومساعدة المشتركين والإجابة على استفساراتهم بهدف تطوير الخدمات الطبية بما يتوافق مع المستوى المطلوب ونحن على استعداد لتنظيم اجتماعات دورية لمناقشة الجوانب التقنية والمالية المتعلقة بالتعاقدات. 
            `,
            en: `
            We are always ready to provide support and assistance to subscribers and answer their inquiries with the aim of developing medical services in accordance with the required level. We are ready to organize periodic meetings to discuss the technical and financial aspects related to contracts.
            `
        },
        img: require('src/assets/images/services/6.png'),
    },
    {
        title: {
            ar: "قنوات اتصال مرنة وفعالة",
            en: "Flexible and effective communication channels"
        },
        text: {
            ar: `
            تتميز خدمتنا بفعالية فائقة ومرونة في التواصل ,مما يعزز التفاعل السريع والفعال  .حيث يمكن لشركائنا الاستفادة من قنوات اتصال فورية و متعددة بما في ذلك الرسائل النصية والبريد الالكتروني والدردشة عبر الانترنت. 
            `,
            en: `
            Our service is characterized by superior effectiveness and flexibility in communication, which enhances quick and effective interaction. Our partners can benefit from multiple instant communication channels, including text messages, e-mail, and online chat.
            `
        },
        img: require('src/assets/images/services/7.png'),
    },
    {
        title: {
            ar: "معالجة المطالبات وتوقع النفقات",
            en: "Processing claims and forecasting expenses"
        },
        text: {
            ar: `
            نحن نقدم حلول شاملة لمعالجة المطالبات بالإعتماد على نظم محاسبة شفافة وتقارير دقيقة وبوسائل متقدمة  تتيح لكم تتبع المطالبات بفاعلية عالية، ونقدم تقارير مفصلة توضح تكاليف المطالبات والنفقات المتوقعة بشكل دوري مما يمكنكم من اتخاذ قرارات استراتيجية مستندة الى بيانات دقيقة. 
            `,
            en: `
            We provide comprehensive solutions for processing claims by relying on transparent accounting systems, accurate reports, and advanced methods that allow you to track claims with high effectiveness. We provide detailed reports showing claims costs and expected expenses on a regular basis, which enables you to make strategic decisions based on accurate data.
            `
        },
        img: require('src/assets/images/services/8.png'),
    },
    {
        title: {
            ar: "إدارة شاملة لشبكة مقدمي الخدمات الصحية",
            en: "Comprehensive management of the network of health service providers"
        },
        text: {
            ar: `
            تقدم شبكتنا حلا شاملا لإدارة شبكة مقدمي الرعاية الصحية بشكل فعال ومتكامل. نعمل على تيسير التواصل وتنظيم العمليات بين مقدمي الخدمات الصحية والمرضى مع التركيز على توفير تجربة مثالية من خلال شبكة واسعة تضم أطباء وعيادات وصيدليات ومختبرات تحليل ومراكز تصوير طبي ومقدمي خدمات صحية  . 
            `,
            en: `
            Our network offers a comprehensive solution for effective and integrated healthcare provider network management. We work to facilitate communication and organize processes between health service providers and patients, with a focus on providing an ideal experience through a wide network that includes doctors, clinics, pharmacies, analysis laboratories, medical imaging centers, and health service providers.
            `
        },
        img: require('src/assets/images/services/9.png'),
    },
    {
        title: {
            ar: "نظام طلب الموافقة الفورية",
            en: "Instant approval request system"
        },
        text: {
            ar: `
            خدمتنا تشمل نظاما حديثا وفعالا لإدارة عمليات طلب الموافقة الفورية مما يعزز التنسيق بين مقدمي الخدمات الصحية وشريك العمل تشمل المزايا: التنسيق الفوري- رصد الحالة- تيسير التواصل- تقارير شاملة باستخدام تكنولوجيا التواصل اللحظي نسعى لتسهيل عمليات طلب الموافقة مما يعزز التنسيق بين الأطراف المعنية. 
            `,
            en: `
            Our service includes a modern and effective system for managing immediate approval request processes, which enhances coordination between health service providers and the business partner. Advantages include: immediate coordination - monitoring the situation - facilitating communication - comprehensive reports using real-time communication technology. We seek to facilitate approval request processes, which enhances coordination between the parties concerned.
            `
        },
        img: require('src/assets/images/services/10.png'),
    },
    {
        title: {
            ar: "توفير نماذج المعاملات الإدارية المطلوبة",
            en: "Providing the required administrative transaction forms"
        },
        text: {
            ar: `
            تقدم شركة الرعاية العالمية خدمات واجهة مبسطة وفعالة لتنزيل النماذج الضرورية للمطالبات وطلبات الموافقة المسبقة بشكل مباشر وسهل. يتيح هذا توفير المزيد من الوقت وتقليل الجهد للمرضى ومقدمي الرعاية والخدمات الصحية على حد السواء مع خدماتنا يمكن للمستفيدين تحميل النماذج الضرورية بكل يسر وسرعة.
            `,
            en: `
            Global Care offers a streamlined and efficient interface to download necessary forms for claims and pre-approval requests directly and easily. This saves more time and reduces effort for patients, caregivers and health services alike. With our services, beneficiaries can download the necessary forms easily and quickly.
            `
        },
        img: require('src/assets/images/services/11.png'),
    },
]

export default services