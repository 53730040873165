import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "src/components";
import classes from './styles.module.css';
import data from 'src/data/services';
import { useLocation } from "react-router-dom";

export default function () {
    const { t, i18n } = useTranslation(['services']);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (<main>
        <Header title={t("header.title")} />
        <section className={classes.items__list}>
            <div className="container">
                <div className="row">
                    {data.map((item, index) => {
                        return <div key={`service-item-${index}`} className="col-12">
                            <div className={`${classes.item} ${index % 2 != 0 ? classes.reversed : ''}`}>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div className={classes.img__container}>
                                            <img src={item.img}
                                                alt={item.title[i18n.language]} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-8">
                                        <div className={classes.content}>
                                            <h3>
                                                {item.title[i18n.language]}
                                            </h3>
                                            <p>
                                                {item.text[i18n.language]}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </div>
            </div>
        </section>
    </main>
    )
}

