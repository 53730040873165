
export type networkType = {
    name: {
        [key: string]: string
    },

    img: any
}

const network: networkType[] = [
    {
        name: {
            ar: "ليبيا",
            en: "Libya"
        },

        img: require('src/assets/images/network/libya.png')
    },
    {
        name: {
            ar: "تونس",
            en: "tunisia"
        },
        img: require('src/assets/images/network/tunisia.png')
    },

    {
        name: {
            ar: "مصر",
            en: "egypt"
        },

        img: require('src/assets/images/network/egypt.png')
    },
    {
        name: {
            ar: "الأردن",
            en: "Jordan"
        },
        img: require('src/assets/images/network/jordan.png')
    },
    {
        name: {
            ar: "تركيا",
            en: "turkey"
        },
        img: require('src/assets/images/network/turkey.png')
    },


    {
        name: {
            ar: "أوروبا",
            en: "Europe"
        },
        img: require('src/assets/images/network/europe.png')
    }

]

export default network;