import Img from 'src/assets/images/about/goals/img_1.svg';
import Img2 from 'src/assets/images/about/goals/img_2.svg';
import Img3 from 'src/assets/images/about/goals/img_3.svg';
import Img4 from 'src/assets/images/about/goals/img_4.svg';
import Img5 from 'src/assets/images/about/goals/img_5.svg';


export type itemType = {
    title: {
        ar: string,
        en: string,
        [lang: string]: string
    },
    text: {
        ar: string,
        en: string,
        [key: string]: string
    },
    img: any,
}

type itemsType = itemType[]


export type goalType = {
    icon: any,
    title: {
        [key: string]: string
    },
    text: {
        [key: string]: string
    }
}


const data: itemsType = [
    {
        title: {
            ar: "من نحن؟",
            en: "Who are we ?"
        },
        text: {
            ar: "تعد شركة الرعاية العالمية شركة رائدة في مجالها, ومقرها في طرابلس, ليبيا. نحن متخصصون في تقديم حلول الرعاية الصحية بأسعار منافسة وخدمات إدارة فعالة للشركات وموظفيها, لذا فنحن نعمل بذكاء لتحقيق أفضل المكاسب في أقل الأوقات. هدفنا هو مساعدة الشركات في تقديم أفضل رعاية صحية لموظفيها مع تحسين تخصيص الميزانية لنفقات الرعاية الصحية",
            en: "Global Care Company is a leading company in its field, headquartered in Tripoli, Libya. We specialize in providing healthcare solutions at competitive prices and effective management services for companies and their employees, so we work intelligently to achieve the best gains in the least time. Our goal is to help companies provide the best healthcare to their employees while optimizing budget allocation for healthcare expenses."
        },
        img: require('src/assets/images/about/img1.png'),

    },
    {
        title: {
            ar: "ما الذي نقدمه ؟",
            en: "What do we offer ?"
        },
        text: {
            ar: `
            تلتزم شركة الرعاية العالمية بتحقيق علاقة مربحة ومستدامة بين جميع الأطراف في معادلة الرعاية الصحية, ونعمل على تحقيق تواصل سلس وسريع بينهم. نحقق ذلك من خلال الشراكة مع مقدمي الرعاية الصحية الموثوق بهم , والمفاوضة على تقديم الأفضل من حيث الجودة و التكلفة ,وضمان الوصول السريع والمبسط لخدمات الرعاية الصحية لعملائنا.
            `,
            en: `
            International Care Company is committed to achieving a profitable and sustainable relationship between all parties in the health care equation, and we work to achieve smooth and rapid communication between them. We achieve this by partnering with trusted healthcare providers, negotiating to deliver the best in terms of quality and cost, and ensuring fast and simplified access to healthcare services for our clients.
            `
        },
        img: require('src/assets/images/about/img2.jpg')
    },
    {
        title: {
            ar: "فلسفتنا",
            en: "Our philosophy"
        },
        text: {
            ar: `
            تدور فلسفة الشركة حول خلق علاقة مربحة لجميع شركائنا . حيث نولي أهمية في إختيارنا بالتعاون مع مقدمي الرعاية الصحية الأفضل والأكثر موثوقية, وضمان الأفضل  من حيث الجودة والتكلفة. نهدف إلى تسهيل الوصول السريع والمبسط للخدمات مع ضمان معالجة الطلبات وإدارتها بشفافية لتسريع العمليات المالية لمقدمي الخدمات. 
            `,
            en: `
            The company's philosophy revolves around creating a profitable relationship for all our partners. We attach importance to our selection of cooperating with the best and most reliable health care providers, and ensuring the best in terms of quality and cost. We aim to facilitate fast and simplified access to services while ensuring that requests are processed and managed transparently to speed up the financial processes of service providers.
            `
        },
        img: require('src/assets/images/about/img3.jpg')
    }

]

export const goals: goalType[] = [
    {
        icon: Img,
        title: {
            ar: "تقديم رعاية صحية متميزة",
            en: "Providing distinguished health care"
        },
        text: {
            ar: "هدفنا مساعدة الشركات في تحسين جودة الرعاية الصحية التي يتلقاها موظفوها من خلال شبكة مقدمي الخدمات الصحية الموثوق بهم وحلول التكنولوجيا المتقدمة لضمان رعاية صحية عالية الجودة للموظفين.",
            en: "Our goal is to help companies improve the quality of healthcare their employees receive through a network of trusted health providers and advanced technology solutions to ensure high-quality healthcare for employees."
        }
    },
    {
        icon: Img2,
        title: {
            ar: "خفض التكاليف",
            en: "Reducing costs"
        },
        text: {
            ar: "تسعى شركتنا إلى تحقيق التوازن بين تقديم الرعاية الصحية المتميزة والتوفير في التكاليف لذلك نقدم حلولا مالية فعالة تساعد الشركات على تخصيص الميزانية الخاصة بخدمات الرعاية الصحية لتحسين كفاءة الإنفاق",
            en: "Our company seeks to achieve a balance between providing distinguished health care and saving costs. Therefore, we provide effective financial solutions that help companies allocate the budget for health care services to improve spending efficiency."
        }
    },
    {
        icon: Img3,
        title: {
            ar: "تبسيط إدارة النفقات",
            en: "Simplify expense management"
        },
        text: {
            ar: "نهدف إلى تبسيط العمليات وتحسين تجربة العملاء والموظفين عن طريق تقديم حلول إدارية فعالة بما في ذلك معالجة الطلبات وإدارة الشبكات وتنسيق الخدمات بين مقدمي الرعاية الصحية والشركة أو أصحاب العمل",
            en: "We aim to simplify operations and improve customer and employee experience by providing effective management solutions including order processing, network management and coordination of services between healthcare providers and the company or employers."
        }
    },
    {
        icon: Img4,
        title: {
            ar: "الاستجابة مرنة",
            en: "Flexible response"
        },
        text: {
            ar: "لقد طورنا استراتيجيات لإدارة الأزمات الصحية مثل الجائحات والتحديات من خلال توفير الدعم اللازم وتعزيز الاستعداد لمثل هذه الحالات بهدف ضمان استمرارية تقديم الخدمات الصحية اللازمة في جميع الظروف",
            en: "We have developed strategies to manage health crises such as pandemics and challenges by providing the necessary support and enhancing preparedness for such situations with the aim of ensuring the continuity of providing the necessary health services in all circumstances."
        }
    },
    {
        icon: Img5,
        title: {
            ar: "علاقات موثوقة",
            en: "Trusted relationships"
        },
        text: {
            ar: "تهدف شركتنا إلى تقديم كل الحلول المناسبة من معالجة الطلبات وإدارة شبكات مقدمي والرعاية الصحية وتنسيق الخدمات والربط بين مقدمي الرعاية الصحية والشركات وأصحاب العمل لتحسين تجربة المستخدم.",
            en: "Our company aims to provide all appropriate solutions from processing requests, managing healthcare provider networks, coordinating services, and linking healthcare providers, companies, and employers to improve the user experience."
        }
    }
]

export default data;