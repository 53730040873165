import React from 'react';
import classes from './styles.module.css';

function Page404() {
    return (
        <div className={classes.container}>
            <div className='text'>
                لم يتم العثور على الصفحة
            </div>

        </div>
    )
}

export default Page404