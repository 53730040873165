import { TFunction } from 'i18next';
import classes from './styles.module.css';


export default function Title({ t, lang }: { t: TFunction, lang: string }) {
    return (<div className={classes.title}>
        <div>
            {t("network.title.sm")}
        </div>
        <div>
            {t("network.title.lg")}
        </div>
        <p>
            {lang.includes("ar") ?
                <>
                    نغطي نسبة <span>
                        95%
                    </span>
                    من مناطق ليبيا
                </>
                : <>
                    We cover
                    <span> 95% </span>
                    of Libya's regions
                </>}
        </p>
    </div>
    )
}
