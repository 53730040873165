import { Routes, Route } from 'react-router-dom';



import './setup/i18n/i18n';

import Layout from 'src/layouts/Layout';
import SndLayout from 'src/layouts/SndLayout';
import { Home, Services, Contact, About, Privacy } from 'src/pages';
import { Page404 } from 'src/components';


const routes = [
	{
		path: '/',
		component: Home,
		label: 'Home',
	},
	{
		path: '/services',
		component: Services,
		label: 'Services',
	},
];

function App() {
	return (<Routes>
		<Route path="/" element={<SndLayout />}>
			<Route path="contact" element={<Contact />} />
		</Route>
		<Route path="/" element={<Layout />}>
			<Route index element={<Home />} />
			<Route path="services" element={<Services />} />
			<Route path="about" element={<About />} />
			<Route path="privacy" element={<Privacy />} />

			<Route path="*" element={<Page404 />} />
		</Route>

	</Routes>
	);
}

export default App;
