import React from 'react'
import classes from './styles.module.css';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
import { Envelope, Phone } from 'src/components/icons';
// import { BsFillTelephoneFill, BsFillEnvelopeFill } from 'react-icons/bs';




function Footer(props: { t: TFunction, lang: string }) {
    const Links = [
        {
            name: props.t("footer.links.about"),
            to: "/about"
        },
        {
            name: props.t("footer.links.services"),
            to: "/services"
        },
        {
            name: props.t("footer.links.contact"),
            to: "/contact"
        },
        {
            name: props.t("footer.links.privacy"),
            to: "/privacy"
        }

    ]
    return (
        <section className={classes.footer}>
            <div className='container'>
                <div className={classes.contact}>
                    <div className={classes.location}>
                        <h4>
                            {props.t("footer.location")}
                        </h4>
                        <div>
                            <iframe title="GCC Location" width="370" height="227" allowFullScreen
                                src="https://maps.google.com/maps?q=32.85663802812998, 13.081782898955826&hl=ar&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        </div>
                    </div>

                    <div className={classes.list}>
                        <h4>
                            {props.t("footer.links.title")}
                        </h4>
                        <ul>
                            {Links.map((item, index) => {
                                return <li key={`links-key-${index}`}>
                                    <Link className={classes.link} to={item.to}>
                                        {item.name}
                                    </Link>
                                </li>
                            })}
                        </ul>
                    </div>

                    <div className={classes.list}>
                        <h4>
                            {props.t("footer.contact_info")}
                            <div className='underline'>
                                <div>
                                </div>
                            </div>
                        </h4>
                        <ul>
                            <li>
                                <a className={classes.link} href="mailto:info@gcc.com.ly">
                                    <Envelope />
                                    info@gcc.com.ly
                                </a>
                            </li>
                            <li>
                                <span className={classes.link}>
                                    <Phone />
                                    {props.lang.includes('ar') ? "5775 007 92 218+" : "+218 92 007 5775"}
                                </span>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className={classes.copyright}>
                    <div>

                        {props.t("footer.copyright")}

                    </div>
                    <div>
                        {props.lang.includes('ar')
                            ?
                            <>
                                تم تصميم وتطوير هذا الموقع بواسطة فريق
                                <a href='https://libyadigitalwizard.com'>
                                    LDW
                                </a>
                            </>
                            : <>
                                This website is designed and developed by  <a href='https://libyadigitalwizard.com'>
                                    LDW
                                </a> team

                            </>}

                    </div>
                </div>
            </div>


        </section >
    )
}

export default Footer