// ContactForm.tsx
import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import classes from './styles.module.css';
import { TFunction } from 'i18next';
import BtnSpinner from './Spinner';
import { toast } from 'react-toastify';
import axios from 'axios';
import { capitalizeWord } from 'src/utils/Utils';
import baseUrl from 'src/constants/vars';

// Define the form values type
interface FormValues {
    fullname: string;
    email: string;
    phone_number: string;
    subject: string;
    message: string;
}

const initialValues: FormValues = {
    fullname: '',
    email: '',
    phone_number: '',
    subject: '',
    message: '',
};


const fields = [
    { id: 'fullname', name: 'form.fullname', type: "text", classes: "col-md-6 col-12" },
    { id: 'email', name: 'form.email', type: "email", classes: "col-md-6 col-12" },
    { id: 'phone_number', name: 'form.phone', type: "tel", classes: "col-md-6 col-12" },
    { id: 'subject', name: 'form.subject', type: "text", classes: "col-md-6 col-12" },
    { id: 'message', name: 'form.message', as: "textarea", classes: "col-12" },
];


const spaceValidator = (value: any) => value && value.trim() !== ''

const validationSchema = (t: TFunction) => Yup.object({
    fullname: Yup.string().required(t('form.error.required_field')).test('no-spaces', t('form.error.space'), spaceValidator),
    email: Yup.string().email(t('form.error.invalid_email')).required(t('form.error.required_field')).test('no-spaces', t('form.error.space'), spaceValidator),
    phone_number: Yup.string()
        .required(t('form.error.required_field'))
        .test('no-spaces', t('form.error.space'), spaceValidator)
        .matches(/^(?:\+\d{1,3}\s?)?(?:\(\d{1,4}\))?(?:[\d-.\s]){8,}$/, t('form.error.invalid_phone_number')),
    subject: Yup.string().required(t('form.error.required_field')).test('no-spaces', t('form.error.space'), spaceValidator),
    message: Yup.string().required(t('form.error.required_field')).test('no-spaces', t('form.error.space'), spaceValidator),
});


const ContactForm = ({ t, lang }: { t: TFunction, lang: string }) => {

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (values: FormValues, { resetForm }: any) => {
        setIsLoading(true);
        try {
            // ${process.env.NEXT_PUBLIC_BASE_URL}
            const res = await axios.post(`${baseUrl}/emails/send/`, values);
            toast.success(t("form.success_msg"), {
                position: lang === 'ar' ? "bottom-right" : "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } catch (err) {
            toast.error(t("form.error_msg"), {
                position: lang === 'ar' ? "bottom-right" : "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
        setIsLoading(false);
        resetForm();
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => validationSchema(t)}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, isValid, resetForm }) => (
                <Form>
                    <div className='row'>
                        {fields.map(item => {
                            return <div key={`field-${item.id}`} className={`${item.classes} mb-4`}>
                                <div className={classes.field}>
                                    <Field className={classes.input} as={item.as ? item.as : null}
                                        type={item.type ? item.type : null}
                                        id={item.id} name={item.id} placeholder={capitalizeWord(t(item.name))} />
                                    <ErrorMessage className={classes.error} name={item.id} component="div" />
                                </div>
                            </div>
                        })}

                        <div className={`col-12 ${classes.btn}`}>
                            <button type="submit" className={`btn ${isLoading || !isValid ? "disabled" : ''}`}>
                                <span>
                                    {
                                        isLoading ?
                                            <BtnSpinner />
                                            : t("form.submit")
                                    }
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
            )}


        </Formik>
    );
};

export default ContactForm;
