import classes from './styles.module.css';
import { TFunction } from 'i18next';
import Title from './title/Title';
import data, { networkType } from 'src/data/network';

export default function Network({ t, lang }: { t: TFunction, lang: string }) {

    return (<section className={classes.network}>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div className={classes.title}>
                        <Title t={t} lang={lang} />
                    </div>

                </div>
                <div className={classes.container}>
                    <div className={classes.items}>
                        <div className='row'>
                            {data.map((item: networkType, index) => {
                                return <div key={`network-key-${index}`} className='col-lg-2 col-sm-4 col-6 m-auto'>
                                    <div className={classes.item}>
                                        <div className={classes.flag}>
                                            <img src={item.img} loading='lazy'
                                                height={80} width={80}
                                                alt={item.name[lang]} />
                                        </div>
                                        <div className={classes.name}>
                                            {item.name[lang]}
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}
