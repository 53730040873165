import classes from './styles.module.css';
import { TFunction } from 'i18next';
import { Element } from 'react-scroll';
import { Title } from 'src/components';
import data, { itemType } from 'src/data/abouts';


function About(props: { lang: string, t: TFunction }) {

    return (
        <Element name="aboutUs" className={classes.about}>
            <div className='container'>
                <div className={classes.title__container}>
                    <Title smTitle={props.t("about.title.sm")} lgTitle={props.t("about.title.lg")} />
                </div>
                {data.map((item: itemType, index) => {
                    return <div key={`about-item-${index}`} className={`${classes.item} ${index % 2 === 0 ? classes.reversed : ""} row`}>
                        <div className='col-lg-7'>
                            <div className={classes.item__content}>
                                <div className={classes.item__content__title}>
                                    <h3>
                                        {item.title[props.lang]}
                                    </h3>
                                    <p>
                                        {item.text[props.lang]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className={classes.item__img}>
                                <img src={item.img} loading='lazy'
                                    height={380} width={348}
                                    alt={item.title[props.lang]} />
                            </div>
                        </div>
                    </div>

                })}

            </div>
        </Element>
    )
}

export default About