import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Nav from 'src/layouts/nav/Nav';
import Footer from 'src/layouts/footer/CopyRightFooter';


function SndLayout() {
    const { t, i18n } = useTranslation(['common']);

    useEffect(() => {
        document.title = t('title');
    }, [i18n.language]);


    return (
        <div className={`main__con ${i18n.language.includes('ar') && 'ar'}`}>
            <Helmet>
                <title>{t('title')}</title>
            </Helmet>
            <Nav t={t} i18n={i18n} />
            <Outlet />
            <Footer t={t} lang={i18n.language} />
            <ToastContainer closeButton={false} />
        </div>
    )
}


export default SndLayout;
