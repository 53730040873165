import React from 'react';
import classes from './styles.module.css';

function Header({ title }: { title: string }) {
    return (
        <div className={classes.container}>
            <h1>
                {title}
            </h1>
        </div>
    )
}

export default Header