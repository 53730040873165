import { useTranslation } from "react-i18next";
import { Header } from "src/components";
import classes from './styles.module.css';
import { toast } from 'react-toastify';
import ContactForm from './Form';
import { Phone, Envelope } from "src/components/icons";


export default function () {
    const { t, i18n } = useTranslation(['contact']);

    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            return true;
        } catch (err) {
            return false
        }
    };

    const phoneCopyHandler = async () => {
        const copied = await copyToClipBoard('+218920075775');
        if (copied) {
            const text = i18n.language === 'ar' ? 'تم النسخ 👍' : 'Copied 👍'
            toast(text, {
                style: { width: 120 },
                position: i18n.language === 'ar' ? "bottom-right" : "bottom-left",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        } else {
            console.log("Error");
        }
    }


    const linkHandler = (e: React.MouseEvent<HTMLElement>, url: string) => {
        window.open(url, '_blank');
    };


    return (<main className={classes.contact}>
        <Header title={t("header.title")} />
        <section>
            <div className="container-md">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className={classes.form}>
                            <div className={classes.title}>
                                {t('send_msg')}
                            </div>
                            <ContactForm t={t} lang={i18n.language} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className={classes.info__container}>
                            <div className={classes.info}>
                                <div className={classes.title}>
                                    {t('contact_info')}
                                </div>
                                <div className={classes.infoItem} onClick={phoneCopyHandler}>
                                    <div className={classes.infoItemIcon}>
                                        <Phone />
                                    </div>
                                    <div className={classes.infoContent}>
                                        <h4>  {t("info.phone_number.name")} </h4>
                                        <p> {t("info.phone_number.value")} </p>
                                    </div>
                                </div>
                                <div className={classes.infoItem} onClick={(e) => linkHandler(e, "mailto:info@gcc.com.ly")}>
                                    <div className={classes.infoItemIcon}>
                                        <Envelope />
                                    </div>

                                    <div className={classes.infoContent}>
                                        <h4> {t("info.email.name")} </h4>
                                        <p>
                                            {t("info.email.value")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.location}>
                                <iframe title="GCC Location" width="370" height="227" allowFullScreen
                                    src="https://maps.google.com/maps?q=32.85663802812998, 13.081782898955826&hl=ar&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    </main>
    )
}

