import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';


import common_en from 'src/setup/i18n/locales/en/common.json';
import common_ar from 'src/setup/i18n/locales/ar/common.json';
import home_en from 'src/setup/i18n/locales/en/home.json';
import home_ar from 'src/setup/i18n/locales/ar/home.json';
import services_en from 'src/setup/i18n/locales/en/services.json';
import services_ar from 'src/setup/i18n/locales/ar/services.json';
import contact_en from 'src/setup/i18n/locales/en/contact.json';
import contact_ar from 'src/setup/i18n/locales/ar/contact.json';
import about_en from 'src/setup/i18n/locales/en/about.json';
import about_ar from 'src/setup/i18n/locales/ar/about.json';


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ["en", "ar"],
        resources: {
            en: {
                common: common_en,
                home: home_en,
                services: services_en,
                contact: contact_en,
                about: about_en
            },
            ar: {
                common: common_ar,
                home: home_ar,
                services: services_ar,
                contact: contact_ar,
                about: about_ar
            }
        },
        ns: ['common', 'home'],
        defaultNS: 'common',
        debug: process.env.NODE_ENV === "development",

    });


export default i18n;