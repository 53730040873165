import classes from './styles.module.css';

type titleProps = {
    align?: string,
    lgTitleColor?: string,
    smTitleColor?: string,
    smTitle: string,
    lgTitle: string
}

export default function Title(props: titleProps) {
    return (<div className={classes.title} style={{ alignItems: props.align || "center" }}>
        <div style={props.smTitleColor ? { color: props.smTitleColor } : {}}>
            {props.smTitle}
        </div>
        <div style={props.lgTitleColor ? { color: props.lgTitleColor } : {}}>
            {props.lgTitle}
        </div>
    </div>
    )
}
