import classes from './styles.module.css';
import { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { Link } from 'react-scroll';


function Hero({ t, lang }: { t: TFunction, lang: string }) {
    return (
        <section className={`${classes.hero} ${lang.includes('ar') ? classes.ar__bg : classes.en__bg}`}>
            <div className={classes.content}>
                <h1 className={classes.title}>
                    <span>
                        {t('hero.title.part_1')}
                    </span>
                    <span>
                        {t('hero.title.part_2')}
                    </span>
                </h1>
                <p>
                    {t('hero.text')}
                </p>
                <Link to={'aboutUs'} href='about' smooth={true} duration={600}
                    className={`${classes.aboutBtn} btn`}>
                    <span>
                        {t('hero.btn')}
                    </span>
                </Link>
            </div>
        </section>)
}


function SmHero({ t }: { t: TFunction }) {
    return (
        <section className={classes.sm__hero}>
            <div className={classes.sm__content}>
                <h1 className={classes.title}>
                    <span>
                        {t('hero.title.part_1')}
                    </span>
                    <span>
                        {t('hero.title.part_2')}
                    </span>
                </h1>
                <p>
                    {t('hero.text')}
                </p>
                <Link to={'aboutUs'} href='about' smooth={true} duration={600}
                    className={`${classes.aboutBtn} btn`}>
                    <span> {t('hero.btn')}</span>
                </Link>
            </div>
        </section>

    )
}


export default ({ t, lang }: { t: TFunction, lang: string }) => {

    // Get Window Width ==================
    const getWidth = (hasWindow: boolean) => {
        const width = hasWindow ? window.innerWidth : 0;
        return width
    }

    const [width, setWidth] = useState(0);


    useEffect(() => {
        const hasWindow = typeof window !== 'undefined';
        setWidth(getWidth(hasWindow));

        if (hasWindow) {
            const handleResize = () => {
                setWidth(getWidth(hasWindow));
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);


    return width >= 1119 ? <Hero t={t} lang={lang} /> : <SmHero t={t} />

}
