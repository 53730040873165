import { useTranslation } from "react-i18next";
import { Header } from "src/components";
import classes from './styles.module.css';
import './styles.css'
import data, { itemType, goals, goalType } from 'src/data/abouts';

// #
export default function () {
    const { t, i18n } = useTranslation(['about']);

    return (<div className={classes.about}>
        <Header title={t("header.title")} />
        <div className='container'>
            <div className={classes.items__container}>
                {data.map((item: itemType, index) => {
                    return <div key={`about-item-${index}`} className={`${classes.item} ${index % 2 === 0 ? classes.reversed : ""} row`}>
                        <div className='col-lg-7'>
                            <div className={classes.item__content}>
                                <div className={classes.item__content__title}>
                                    <h3>
                                        {item.title[i18n.language]}
                                    </h3>
                                    <p>
                                        {item.text[i18n.language]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className={classes.item__img}>
                                <img src={item.img} loading='lazy' alt={item.title[i18n.language]} />
                            </div>
                        </div>
                    </div>

                })}
            </div>
            <div className={classes.goals}>
                <div className={classes.title}>
                    {t('goals.title')}
                </div>
                <div className={classes.goals__items__container}>
                    <div className="row">
                        {goals.map((item, index) => {
                            return (<div key={`goal-key-${index}`} className="col-12 col-lg-6 mx-auto">
                                <div className={`${classes.card} goal__card`}>
                                    <div className={classes.card__icon}>
                                        <img src={item.icon} loading="lazy"
                                            width="220"
                                            height="160"
                                            alt={item.title[i18n.language]} />
                                    </div>
                                    <h3 className={`${classes.card__title} goal__card__title`}>
                                        {item.title[i18n.language]}
                                    </h3>
                                    <p className={`${classes.card__text} goal__card__text`}>
                                        {item.text[i18n.language]}
                                    </p>
                                </div>
                            </div>)
                        })}

                    </div>
                </div>
            </div>


        </div>
    </div>
    )
}

