import { useState, useEffect, useCallback } from 'react';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';

import classes from './styles.module.css';
import { Menu, XMark } from 'src/components/icons';

import logo from 'src/assets/images/logo.svg';
import bgLogo from 'src/assets/images/bg_logo.svg';

const navItems = [
    { name: 'nav.home', path: '/' },
    { name: 'nav.services', path: '/services' },
    { name: 'nav.contact', path: '/contact' },
    { name: 'nav.about', path: '/about' },

]


export default function Nav({ t, i18n }: { t: TFunction, i18n: any }) {

    const [showMenu, setShowMenu] = useState(false);

    const [navBG, setNavBG] = useState(false);

    const navBackgroundHandler = (hasWindow: boolean) => {
        if (hasWindow && window.scrollY >= 64) {
            setNavBG(true);

        } else {
            setNavBG(false);
        }
    }

    const hideMenuHandler = useCallback(() => setShowMenu(false), [])


    const langChangeHandler = (e: any) => {
        e.preventDefault();
        i18n.changeLanguage(i18n.language.includes('ar') ? 'en' : 'ar');
    };


    useEffect(() => {
        const hasWindow = typeof window !== 'undefined';
        navBackgroundHandler(hasWindow);

        if (hasWindow) {
            window.addEventListener('scroll', () => navBackgroundHandler(hasWindow))
            return () => {
                window.removeEventListener('scroll', () => navBackgroundHandler(hasWindow))
            }
        }
    }, []);


    return (<header className={`${classes.header} ${navBG ? classes.navBg : ''}`}>
        <nav className={`${classes.nav} container-fluid`}>
            <div className={classes.nav__data}>
                <a href="/" className={classes.nav__logo}>
                    <img width={80} height={23.5} src={logo} alt={i18n.language.includes('ar') ? "شركة الرعاية العالمية" : "GCC"} />
                </a>

                <div className={`${classes.nav__toggle} ${showMenu ? classes.show__icon : ''} `} >
                    <Menu className={`${classes.nav__burger} ${showMenu ? classes.hide__burger__icon : ''} `}
                        onClick={() => {
                            setShowMenu(true);
                        }} />
                    <XMark className={`${classes.nav__close} ${showMenu ? classes.show__close__icon : ''} `}
                        onClick={() => {
                            setShowMenu(false);
                        }}
                    />
                </div>
            </div>

            {/* <!-- =============== NAV MENU =============== --> */}

            <div onClick={() => setShowMenu(false)} className={`${classes.nav__menu} ${showMenu ? classes.show__menu : ''}`} >
                <ul className={classes.nav__list}>
                    {navItems.map((item: any, index) => {
                        return <li key={`nav-key-${index}`}>
                            <Link to={item.path} className={classes.nav__link}>
                                {t(item.name)}
                            </Link>
                        </li>

                    })}
                    <li>
                        <a aria-current={'page'}
                            onClick={langChangeHandler}
                            href='lang'
                            className={`${classes.nav__link} ${i18n.language.includes('ar') ? "en-f" : "ar-f"}`} >
                            {i18n.language.includes('ar') ? "EN" : "العربية"}
                        </a>
                    </li>
                </ul>
            </div>
        </nav >
    </header >
    )
}
