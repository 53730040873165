import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import './styles.css';

function Privacy() {
    const { t } = useTranslation(["common"]); // Initialize the useTranslation hook

    return (
        <div className="privacy text-center my-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{t('privacy_policy.title')}</h2>
                        <p>{t('privacy_policy.intro')}</p>
                        <p>{t('privacy_policy.additional_info')}</p>

                        <h3>{t('privacy_policy.log_files.title')}</h3>
                        <p>{t('privacy_policy.log_files.description')}</p>

                        <h3>{t('privacy_policy.privacy_policies.title')}</h3>
                        <p>{t('privacy_policy.privacy_policies.description')}</p>

                        <h3>{t('privacy_policy.third_party_policies.title')}</h3>
                        <p>{t('privacy_policy.third_party_policies.description')}</p>

                        <p>{t('privacy_policy.third_party_policies.cookie_instructions')}</p>

                        <h3>{t('privacy_policy.children_info.title')}</h3>
                        <p>{t('privacy_policy.children_info.description')}</p>

                        <h3>{t('privacy_policy.online_policy_only.title')}</h3>
                        <p>{t('privacy_policy.online_policy_only.description')}</p>

                        <h3>{t('privacy_policy.consent.title')}</h3>
                        <p>{t('privacy_policy.consent.description')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Privacy;
