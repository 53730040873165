import { useEffect } from 'react';
import classes from './styles.module.css';
import './styles.css'
import { Link } from 'react-router-dom';
import { ServiceCardType, services_cards as data } from 'src/data/services';
import { Title } from 'src/components';
import { TFunction } from 'i18next';
import { Element } from 'react-scroll';


export default function Services(props: { t: TFunction, lang: string }) {


    return (
        <Element name="services" as='section' className={`${classes.services__section} home__services`}>
            <div className='container'>
                <div className={classes.title}>
                    <Title lgTitle={props.t("services.title.lg")} smTitle={props.t("services.title.sm")} />
                </div>
                <div className='row'>

                    {data.map((item: ServiceCardType, index) => {
                        return <div key={`service-key-${index}`} className='col-xl-4 col-12 col-lg-6'>
                            <div className={`${classes.card} item__card`}>
                                <div className={classes.number}>
                                    {index + 1}
                                </div>

                                <h4>
                                    {item.title[props.lang]}
                                </h4>

                                <p>
                                    {item.text[props.lang]}
                                </p>
                            </div>
                        </div>
                    })}

                    <div className='col-12'>
                        <div className={classes.moreBtn}>
                            <Link to={'/services'} className="btn">
                                <span>
                                    {props.t('services.more_btn')}
                                </span>
                            </Link>
                        </div>
                    </div>

                </div>

            </div>
        </Element >

    )
}
