import classes from './styles.module.css';
import { TFunction } from 'i18next';



function Footer(props: { t: TFunction, lang: string }) {
    return (
        <section className={classes.copyright__footer}>
            <div className='container'>
                <div className={classes.copyright}>
                    <div>

                        {props.t("footer.copyright")}

                    </div>
                    <div>
                        {props.lang.includes('ar')
                            ?
                            <>
                                تم تصميم وتطوير هذا الموقع بواسطة فريق
                                <a href='https://libyadigitalwizard.com'>
                                    LDW
                                </a>
                            </>
                            : <>
                                This website is designed and developed by  <a href='https://libyadigitalwizard.com'>
                                    LDW
                                </a> team

                            </>}

                    </div>
                </div>
            </div>


        </section>
    )
}

export default Footer